import { Box } from '@material-ui/core'
import React from 'react'

import CustomTable, { CustomTableProps } from '~/components/Table'

import { AbTestMetricsForTableWithModalData } from './useGetAbTestMetrics'

type AbTestTabMetricsTableProps = {
  variant: 'active' | 'completed'
  data?: AbTestMetricsForTableWithModalData[]
}

/**
 * The metrics table for active and completed AB tests.
 */
export default function AbTestTabMetricsTable({ variant, data }: AbTestTabMetricsTableProps) {
  const headerMap = {
    active: 'Testes em progresso',
    completed: 'Histórico de testes',
  }

  const tableHeaderMap = {
    active: [
      { name: 'Nome da página' },
      { name: 'Nome da variante' },
      { name: 'Tráfego %' },
      { name: 'Visitas' },
      { name: 'Conversões' },
      { name: 'Conversão %' },
      { name: 'Probabilidade de ser campeã' },
      { name: 'Finalizar' },
    ],
    completed: [
      { name: 'Nome da página' },
      { name: 'Nome da variante' },
      { name: 'Data de início' },
      { name: 'Data de término' },
      { name: 'Tráfego %' },
      { name: 'Visitas' },
      { name: 'Conversões' },
      { name: 'Conversão %' },
      { name: 'Probabilidade de ser campeã' },
      { name: 'Status' },
    ],
  } satisfies Record<AbTestTabMetricsTableProps['variant'], CustomTableProps['state']['header']>

  const keysMap = {
    active: [
      'page_name',
      'variant_name',
      'traffic_percent',
      'visits',
      'conversions',
      'conversion_percent',
      'win_chance',
      'action',
    ],
    completed: [
      'page_name',
      'variant_name',
      'start_date',
      'end_date',
      'traffic_percent',
      'visits',
      'conversions',
      'conversion_percent',
      'win_chance',
      'status',
    ],
  }

  return (
    <Box
      style={{
        fontFamily: 'Nunito Sans',
      }}
    >
      <Box px={4} pt={2}>
        <div style={{ fontSize: '1rem' }}>{headerMap[variant]}</div>
      </Box>
      <CustomTable<AbTestMetricsForTableWithModalData>
        state={{
          header: tableHeaderMap[variant],
          rows: data ?? [],
        }}
        keys={keysMap[variant]}
        isDisabled={false}
        backgroundType="2-grouped"
      />
    </Box>
  )
}
