import { Box, Typography } from '@material-ui/core'
import update from 'immutability-helper'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getFunnel } from '~/actions'
import { SET_AB_TEST_CHAMPION_MODAL, SET_ALERT } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import { isAbTestEnabled } from '~/featureToggle'
import axiosNewInstance from '~/services/config/axios'
import { Funnel, Page } from '~/types'
import customFetch from '~/utils/fetch'
import * as sharedData from '~/utils/sharedData'

import * as funnelService from '../../../../services/funnelService'
import DeletePageModal from '../DeletePage'
import DuplicatePageModal from '../DuplicatePage'
import TabsPanel from '../TabsPanel'
import { AddNewPageButton, Card, EditModal } from './components'
import { AbTestVariantCard } from './components/AbTestVariantCard/AbTestVariantCard'
import { ChainLinkComponent } from './components/CardLink'
import { CardPosition } from './components/CardPosition'
import CreatePageModal from './components/CreatePageModal'
import AbTestChampionModal from './components/Modals/AbTestChampionModal'
import { DeleteAbTestModal } from './components/Modals/DeleteAbTestModal'
import EditAbTestModal from './components/Modals/EditAbTestModal'

interface PagesListProps {
  funnel: Funnel
}

function PagesList({ funnel }: PagesListProps) {
  const history = useHistory()
  const [pages, setPages] = useState(funnel.pages)
  const [pageIDSelected, setPageIDSelected] = useState(null)
  const [movePage, setMovePage] = useState(false)
  const appDispatch = useAppDispatch()
  const [openEditModal, setOpenEditModal] = useState(false)
  const [deletePageModal, setDeletePageModal] = useState({
    open: false,
    validator: '',
    pageId: 0,
    funnelId: 0,
  })

  const [duplicatePageModal, setDuplicatePageModal] = useState({
    open: false,
    pageId: 0,
  })

  const [editPageModal, setEditPageModal] = useState({
    open: false,
    pageId: 0,
  })

  type AbTestEditModalState = {
    open: boolean
    pageId: number | null
    variantPageId: number | null
    abTestId: number | null
  }

  const [abTestEditModal, setAbTestEditModal] = useState<AbTestEditModalState>({
    open: false,
    pageId: null,
    variantPageId: null,
    abTestId: null,
  })

  type AbTestDeleteModalState = {
    open: boolean
    name: string
    pageId: number | null
    abTestId: number | null
  }

  const [abTestDeleteModal, setAbTestDeleteModal] = useState<AbTestDeleteModalState>({
    open: false,
    name: '',
    pageId: null,
    abTestId: null,
  })

  const appState = useAppState()

  const { id: funnelId, domain } = funnel
  const numCards = pages.length - 1

  const toggleOpenEditModal = () => setOpenEditModal(!openEditModal)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (movePage) handleUpdateSteps()
    }, 1000)
    function handleUpdateSteps() {
      const newSteps = pages.map((page, index) => ({
        page_id: page.id,
        step: index + 1,
      }))
      const steps = JSON.stringify(newSteps)
      const data = {
        pages: steps,
      }
      try {
        funnelService.updateFunnel(funnelId, data)
      } catch (error) {
        appDispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Houve um problema. Tenta por favor mais tarde!',
            variant: 'error',
          },
        })
      }
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, movePage])

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = pages[dragIndex]
      setPages(
        update(pages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
      setMovePage(true)
    },
    [pages],
  )

  const openTemplate = () => {
    history.push(`/templates/${pageIDSelected}`)
  }

  function openEditor(pageId: number) {
    sharedData.setPageId(typeof pageId === 'number' ? pageId : pageIDSelected)
    sharedData.setFunnelId(funnelId)
    window.location.href = `${process.env.REACT_APP_PAGE_EDITOR_URL}`
  }

  /** Triggers the request to create an AB test. */
  function handleCreateAbTest(pageId: number) {
    // TODO: How to define the type of AB test to be created?
    axiosNewInstance
      .post(`/pages/${pageId}/create-abtest`)
      .then(() => {
        getFunnel(appDispatch, funnelId)
      })
      .then(() => {
        toast.success('Teste AB criado com sucesso!')
      })
      .catch((err) => {
        toast.error('Erro ao criar o teste AB. Tente novamente mais tarde.')
        // eslint-disable-next-line no-console
        console.error('Something went wrong while creating ab test for page', pageId)
        // eslint-disable-next-line no-console
        console.error(err)
      })
  }

  function handleDeleteAbTest() {
    customFetch(
      appDispatch,
      async () => {
        await axiosNewInstance.post(
          `/pages/${abTestDeleteModal.pageId}/stop-abtest/${abTestDeleteModal.abTestId}`,
        )
        await getFunnel(appDispatch, funnelId)
        toast.success('Teste AB removido com sucesso!')
      },
      (error: unknown) => {
        // eslint-disable-next-line no-console
        console.error(
          'Something went wrong while stopping ab test for page',
          abTestDeleteModal.pageId,
        )
        // eslint-disable-next-line no-console
        console.error(error)

        toast.error('Erro ao remover o teste AB. Tente novamente mais tarde.')
      },
    )
  }

  type HandleStartAbTestProps = {
    /** The parent page ID. */
    pageId: number
    /** The ID of the AB test to be started. */
    abTestId: number
  }

  /** Triggers the request to start an AB test. */
  function handleStartAbTest({ pageId, abTestId }: HandleStartAbTestProps) {
    axiosNewInstance
      .post(`/pages/${pageId}/start-abtest/${abTestId}`)
      .then(() => {
        getFunnel(appDispatch, funnelId)
      })
      .then(() => {
        toast.success('Teste AB iniciado com sucesso!')
      })
      .catch((err) => {
        toast.error('Erro ao iniciar o teste AB. Tente novamente mais tarde.')
        // eslint-disable-next-line no-console
        console.error('Something went wrong while starting ab test for page', pageId)
        // eslint-disable-next-line no-console
        console.error(err)
      })
  }

  const renderCard = (page: Page, index: number, disableDragAndDrop: boolean) => {
    const abTest = page.abTest

    const isLastItem = index === numCards

    const handleOpenEditor = page.status === 'empty' ? toggleOpenEditModal : openEditor

    if (abTest && isAbTestEnabled) {
      return (
        <ChainLinkComponent>
          <Card
            key={page.id}
            index={index}
            id={page.id}
            page={page}
            domainUrl={domain.name}
            moveCard={disableDragAndDrop ? undefined : moveCard}
            handleOpenEditor={handleOpenEditor}
            setPageIDSelected={setPageIDSelected}
            setDeletePageModal={setDeletePageModal}
            setDuplicatePageModal={setDuplicatePageModal}
            setEditPageModal={setEditPageModal}
            setAbTestEditModal={() =>
              setAbTestEditModal({
                open: true,
                pageId: page.id,
                abTestId: abTest.id,
                variantPageId: abTest.variant_id,
              })
            }
            hasAbTest
            hasActiveAbTest={abTest.status === 'active'}
            disableDragAndDrop={disableDragAndDrop}
            isLastItem={isLastItem}
          />

          <AbTestVariantCard
            key={abTest.variant_id}
            id={abTest.variant_id}
            index={index}
            page={page}
            abTest={abTest}
            domainUrl={domain.name}
            setDeletePageModal={setAbTestDeleteModal}
            hasActiveAbTest={abTest.status === 'active'}
          />
        </ChainLinkComponent>
      )
    }

    return (
      <Card
        key={page.id}
        index={index}
        id={page.id}
        page={page}
        domainUrl={domain.name}
        moveCard={disableDragAndDrop ? undefined : moveCard}
        handleOpenEditor={handleOpenEditor}
        setPageIDSelected={setPageIDSelected}
        setDeletePageModal={setDeletePageModal}
        setDuplicatePageModal={setDuplicatePageModal}
        setEditPageModal={setEditPageModal}
        setAbTestEditModal={setAbTestEditModal}
        handleCreateAbTest={handleCreateAbTest}
        disableDragAndDrop={disableDragAndDrop}
        isLastItem={isLastItem}
      />
    )
  }

  const hasAtLeastOneActiveAbTest = pages.some((page) => page.abTest !== null)

  return (
    <>
      <Box mb={3}>
        <Typography variant="h4" gutterBottom>
          Páginas
        </Typography>
      </Box>

      {Boolean(pages.length) && <TabsPanel />}

      <AddNewPageButton numberOfPages={pages.length} />

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {pages.map((page, index) => {
          return (
            <div
              key={page.id}
              style={{
                display: 'flex',
                gap: '2.5rem',
                position: 'relative',
              }}
            >
              <CardPosition
                index={index}
                isLast={index === numCards}
                hasArrow={index === numCards - 1}
              />

              {renderCard(page, index, hasAtLeastOneActiveAbTest)}
            </div>
          )
        })}
      </div>

      <EditModal
        open={openEditModal}
        handleClose={toggleOpenEditModal}
        openEditor={openEditor}
        openTemplate={openTemplate}
      />

      <DeletePageModal
        state={{ ...deletePageModal }}
        action={{
          handleClose: () =>
            setDeletePageModal({
              ...deletePageModal,
              open: false,
            }),
        }}
      />

      <DeleteAbTestModal
        {...abTestDeleteModal}
        handleSubmit={handleDeleteAbTest}
        handleClose={() =>
          setAbTestDeleteModal({
            open: false,
            name: '',
            pageId: null,
            abTestId: null,
          })
        }
      />

      <DuplicatePageModal
        state={{ ...duplicatePageModal }}
        action={{
          handleClose: () => setDuplicatePageModal({ pageId: -1, open: false }),
        }}
      />

      <CreatePageModal
        open={editPageModal.open}
        pageId={editPageModal.pageId}
        nextStep={pages.length + 1}
        handleClose={() => setEditPageModal({ pageId: 0, open: false })}
      />

      <EditAbTestModal
        {...abTestEditModal}
        handleStartAbTest={handleStartAbTest}
        handleOpenEditor={openEditor}
        handleClose={() =>
          setAbTestEditModal({
            open: false,
            abTestId: null,
            pageId: null,
            variantPageId: null,
          })
        }
      />

      <AbTestChampionModal
        open={appState.abTestChampionModal.open}
        data={appState.abTestChampionModal.data}
        onSubmit={() => {
          getFunnel(appDispatch, funnelId)
        }}
        handleClose={() => {
          appDispatch({
            type: SET_AB_TEST_CHAMPION_MODAL,
            payload: {
              open: false,
              data: null,
            },
          })
        }}
      />
    </>
  )
}

export default PagesList
