import { Typography } from '@material-ui/core'
import { FiberManualRecord as CircleIcon } from '@material-ui/icons'
import React from 'react'

import TooltipStatus from '~/components/TooltipStatus/TooltipStatus'
import { AbTestStatus, PageStatus, STATUS } from '~/consts'
import colors from '~/utils/colors'

import { useStyles } from './styles'

type StatusBadgeProps = {
  status: PageStatus | AbTestStatus
}

/**
 * Displays the status badge for a given card/page.
 */
export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const classes = useStyles()

  if (status === STATUS.PAGE.BLOCKED) {
    return (
      <TooltipStatus
        className={classes.status}
        style={{ color: colors.danger }}
        label="Desativado"
        title="Este recurso está desativado, por favor entrar em contato com o suporte caso queira ativar novamente."
      />
    )
  }

  const baseStatuses = {
    published: {
      color: colors.success,
      message: 'Publicado',
    },

    activeAbTest: {
      color: colors.orange,
      message: 'Em teste',
    },

    empty: {
      color: colors.erro,
      message: 'Vazia',
    },

    draft: {
      color: colors.danger,
      message: 'Rascunho',
    },

    inactive: {
      color: colors.dark20,
      message: 'Inativo',
    },
  }

  const statusMap = {
    [STATUS.PAGE.PUBLISHED]: baseStatuses.published,
    [STATUS.PAGE.EMPTY]: baseStatuses.empty,
    [STATUS.PAGE.DRAFT]: baseStatuses.draft,
    active: baseStatuses.activeAbTest,
    completed: baseStatuses.inactive,
    inactive: baseStatuses.inactive,
  }

  const selectedStatus = statusMap[status]

  return (
    <Typography
      variant="body1"
      component="p"
      gutterBottom
      className={classes.status}
      style={{ color: selectedStatus?.color }}
    >
      <CircleIcon /> {selectedStatus?.message}
    </Typography>
  )
}
