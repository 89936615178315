import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import {
  Close as CloseIcon,
  Edit as EditIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'

import AbTestAIllustration from '~/assets/images/ab_test_a.svg'
import AbTestBIllustration from '~/assets/images/ab_test_b.svg'
import StartIllustration from '~/assets/images/ab_test_start.svg'
import colors from '~/utils/colors'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(3),
    borderBottom: '1px solid #E5E9F5',
    '& p': {
      textAlign: 'left',
      fontSize: '24px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7, 8),
    padding: 0,
    gap: theme.spacing(4),
  },
  card: {
    border: '2px solid #E5E9F5',
    borderRadius: '20px',
    padding: theme.spacing(6, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 250,

    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    transition: 'all 150ms',
    gap: 20,

    '&.disabled': {
      '& button, & button:hover': {
        background: '#E5E9F5',
        color: '#fff',
        boxShadow: 'none',
        pointerEvents: 'none',
      },
    },

    '& button, & button:hover': {
      background: colors.button.main,
      color: '#fff',
      borderRadius: colors.button.radius,
    },

    '&:hover': {
      border: '2px solid #4C6FFF',
    },
    '&.disabled:hover': {
      border: '2px solid #E5E9F5',
    },
  },
}))

type EditAbTestModalProps = {
  /** Whether the modal is open or not */
  open: boolean
  /** The parent page ID */
  pageId: number | null
  /** The ID of the variant page to be edited */
  variantPageId: number | null
  /** The ID of the AB test to be edited */
  abTestId: number | null
  /** Function to close the modal */
  handleClose: () => void
  /** Function to start an AB test */
  handleStartAbTest: ({ pageId, abTestId }: { pageId: number; abTestId: number }) => void
  /** Function to open the editor */
  handleOpenEditor: (pageId: number) => void
}

export default function EditAbTestModal({
  open,
  pageId,
  abTestId,
  variantPageId,
  handleClose,
  handleStartAbTest,
  handleOpenEditor,
}: EditAbTestModalProps) {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <Typography component="p">Escolha uma das opções abaixo</Typography>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={`${classes.card}`}>
          <img src={AbTestAIllustration} alt="" />
          <Typography variant="h5">Original</Typography>

          <StyledButton
            variant="contained"
            onClick={() => {
              if (pageId) {
                handleOpenEditor(pageId)
              }
            }}
          >
            <StylesButtonContent>
              Editar
              <EditIcon fontSize="inherit" />
            </StylesButtonContent>
          </StyledButton>
        </div>

        <div className={classes.card}>
          <img src={AbTestBIllustration} alt="" />
          <Typography variant="h5">Variante</Typography>

          <StyledButton
            variant="contained"
            onClick={() => {
              if (variantPageId) {
                handleOpenEditor(variantPageId)
              }
            }}
          >
            <StylesButtonContent>
              Editar
              <EditIcon fontSize="inherit" />
            </StylesButtonContent>
          </StyledButton>
        </div>

        <div className={classes.card}>
          <img src={StartIllustration} alt="" />
          <Typography variant="h5">Teste AB</Typography>

          <StyledButton
            variant="contained"
            color="secondary"
            onClick={() => {
              if (pageId && abTestId) {
                handleStartAbTest({ pageId, abTestId })
              }
            }}
          >
            <StylesButtonContent>
              Iniciar teste
              <PlayArrowIcon fontSize="inherit" />
            </StylesButtonContent>
          </StyledButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const StylesButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  svg {
    font-size: 14px;
  }
`

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    boxShadow: 'none',
    width: 150,
  },
  containedSecondary: {
    background: `#17D3B4 !important`,
  },
})(Button)
