/* eslint-disable react/prop-types */

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import arrowsSplitIcon from '~/assets/images/arrows_split.svg'
import duplicateIcon from '~/assets/images/duplicate_icon.svg'
import editIcon from '~/assets/images/edit_icon_blue.svg'
import pageIcon from '~/assets/images/pageIcon.svg'
import trashIcon from '~/assets/images/trash_icon.svg'
import { default as Button } from '~/components/BaseButton'
import { STATUS } from '~/consts'
import { isAbTestEnabled } from '~/featureToggle'
import Menu from '~/pages/Templates/components/Menu'
import menuOption from '~/utils/menuOption'

import PagePathContainer from '../PagePath'
import { StatusBadge } from '../StatusBadge'
import styles, { StyledTooltip } from './styles'

const ItemTypes = {
  CARD: 'card',
}

const useStyle = makeStyles(styles)

export const Card = ({
  id,
  page,
  domainUrl,
  index,
  moveCard,
  handleOpenEditor,
  setPageIDSelected,
  setDeletePageModal,
  setDuplicatePageModal,
  setEditPageModal,
  setAbTestEditModal,
  handleCreateAbTest,
  hasActiveAbTest,
  hasAbTest,
  isAbTestVariant,
  disableDragAndDrop,
  isLastItem,
}) => {
  const classes = useStyle()

  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1

  if (!disableDragAndDrop) {
    drag(drop(ref))
  }

  const setDragMode = (isEditing) => {
    return drag(isEditing ? false : drop(ref))
  }

  const updatedAt = new Date(page.updated_at)

  const editButtonClickHandler = () => {
    setPageIDSelected(page.id)

    if (hasAbTest && !hasActiveAbTest) {
      setAbTestEditModal()
    } else {
      handleOpenEditor(page.id)
    }
  }

  function getMenuOptions(page) {
    return [
      menuOption(editIcon, 'Editar', () =>
        setEditPageModal({
          open: true,
          pageId: page.id,
        }),
      ),

      menuOption(duplicateIcon, 'Duplicar', () =>
        setDuplicatePageModal({
          open: true,
          pageId: page.id,
          id: page.funnel_id,
        }),
      ),

      ...(isAbTestEnabled && page.status === STATUS.PAGE.PUBLISHED && !page?.abTest && !isLastItem
        ? [
            menuOption(arrowsSplitIcon, 'Criar Teste A/B', () => {
              handleCreateAbTest(page.id)
            }),
          ]
        : []),

      menuOption(trashIcon, 'Deletar', () =>
        setDeletePageModal({
          validator: page.name,
          open: true,
          pageId: page.id,
          funnelId: page.funnel_id,
        }),
      ),
    ]
  }

  return (
    <Grid
      container
      className={classes.funnelInfo}
      ref={ref}
      style={{
        opacity,
        transform: `rotate(${isDragging ? -1 : 0}deg)`,
        cursor: disableDragAndDrop ? 'default' : 'grab',
      }}
    >
      <Grid item xs={12} xl={2}>
        <Box display="flex" height="100%" alignItems="center" marginLeft="20px">
          <Box className={classes.dragIcon}>
            <DragHandleIcon m={0} style={{ opacity: disableDragAndDrop ? 0.25 : 1 }} />
          </Box>
          <Box className={classes.typeIcon}>
            <img src={pageIcon} alt="Ícone de uma página" />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography className={`truncate bold`} variant="h6" gutterBottom>
              {page.name}
            </Typography>
            <PagePathContainer
              page={page}
              domainUrl={domainUrl}
              funnelId={page.funnel_id}
              setDragMode={setDragMode}
              readOnly={isAbTestVariant}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} xl={10}>
        <Box className={classes.cardInfos}>
          <div>
            <Typography component="p" gutterBottom>
              Categoria da página
            </Typography>
            <Typography component="span" gutterBottom className="bold">
              {page.page_category.description}
            </Typography>
          </div>
          <div>
            <Typography component="p" gutterBottom>
              Última modificação
            </Typography>
            <Typography component="span" gutterBottom className="bold">
              {updatedAt.toLocaleString('pt-BR', {
                timeZone: 'America/Sao_Paulo',
              })}
            </Typography>
          </div>
          <div className={classes.infoStatus}>
            <Typography component="p" gutterBottom>
              Status
            </Typography>
            <Typography component="span" gutterBottom>
              <StatusBadge status={hasActiveAbTest ? 'active' : page.status} />
            </Typography>
          </div>
          <div className="editButton">
            {isAbTestVariant ? (
              <div style={{ width: 150 }} />
            ) : (
              <EditButton handleClick={editButtonClickHandler} hasActiveAbTest={hasActiveAbTest} />
            )}
          </div>
          <Menu
            className={classes.menu}
            index={index}
            options={getMenuOptions(page)}
            disabled={hasActiveAbTest}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

const EditButton = ({ handleClick, hasActiveAbTest }) => {
  if (hasActiveAbTest) {
    return (
      <AbTestInProgressTooltip>
        <div
          style={{
            cursor: 'not-allowed',
          }}
        >
          <Button
            size="small"
            variant="contained"
            handleClick={handleClick}
            color="primary"
            text="Abrir Editor"
            width={150}
            disabled
          />
        </div>
      </AbTestInProgressTooltip>
    )
  }

  return (
    <Button
      size="small"
      variant="contained"
      handleClick={handleClick}
      color="primary"
      text="Abrir Editor"
      width={150}
    />
  )
}

const AbTestInProgressTooltip = ({ children }) => {
  return (
    <StyledTooltip
      arrow
      placement="top-end"
      title={
        <>
          <Typography
            as="div"
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              fontFamily: 'Nunito Sans',
            }}
            gutterBottom
          >
            <i
              className="fas fa-ban"
              style={{ fontSize: '1rem', marginRight: '0.5rem', color: '#757575' }}
            />
            Não pode ser editado agora
          </Typography>
          <Typography variant="body2">
            Há um Teste A/B ativo. O teste precisa ser finalizado para que novas edições sejam
            realizadas.
          </Typography>
        </>
      }
    >
      {children}
    </StyledTooltip>
  )
}

Card.propTypes = {
  id: PropTypes.number,
  page: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    updated_at: PropTypes.string,
    status: PropTypes.string,
    funnel_id: PropTypes.number,
    page_category: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  setEditPageModal: PropTypes.any,
  domainUrl: PropTypes.string,
  index: PropTypes.number,
  moveCard: PropTypes.func,
  classesNames: PropTypes.arrayOf(PropTypes.string),
  handleOpenEditor: PropTypes.func,
  setPageIDSelected: PropTypes.func,
  setDeletePageModal: PropTypes.func,
  setDuplicatePageModal: PropTypes.func,
  handleCreateAbTest: PropTypes.func,
  setAbTestEditModal: PropTypes.func,
  hasActiveAbTest: PropTypes.bool,
  isAbTestVariant: PropTypes.bool,
  hasAbTest: PropTypes.bool,
  disableDragAndDrop: PropTypes.bool,
  isLastItem: PropTypes.bool,
}

export default Card
