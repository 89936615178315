import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
  createStyles({
    status: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: 0,
      alignItems: 'center',
      '& svg': {
        marginRight: '10px',
        fontSize: '0.7rem',
      },
    },
  }),
)
