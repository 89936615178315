import { createStyles, makeStyles } from '@material-ui/core'

import colors from '~/utils/colors'

export const useStyles = makeStyles((theme) =>
  createStyles({
    typeIcon: {
      background: '#FFF1E9',
      borderRadius: 8,
      width: 53,
      height: 40,
      display: 'flex',
      alignItems: ' center',
      justifyContent: 'center',
      '& svg': {
        color: '#FF9349',
      },
    },
    funnelInfo: {
      background: '#fff',
      minHeight: '64px',
      padding: '0.5rem',
      borderRadius: '8px',
      boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
      fontFamily: 'Nunito Sans',

      '&:hover .show': {
        display: 'block',
        marginLeft: 'auto',
      },

      '& h6': {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        textAlign: 'left',
      },
      '& h6 span': {
        color: '#A0A4A8',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        textAlign: 'left',
      },
      '& .bold': {
        color: '#222',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
      },
      '& .truncate': {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginLeft: '30px',
      },
      '& p': {
        color: '#A0A4A8',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
      },
    },
    cardInfos: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      '& > div': {
        minWidth: '7vw',
        marginLeft: '2.3vw',
      },
      '& .editButton': {
        right: '84px',
      },
      '& .editButton, & > button': {
        position: 'absolute',
        [theme.breakpoints.up('xl')]: {
          position: 'relative',
          right: '0px',
        },
      },
      '& > button': {
        marginLeft: '0.2vw',
        right: '36px',
      },
      justifyContent: 'space-start',
      paddingTop: '26px',
      [theme.breakpoints.up('xl')]: {
        justifyContent: 'flex-end',
        paddingTop: '0px',
      },
    },
    infoStatus: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dragIcon: {
      marginRight: '10px',
      marginLeft: '-15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        fill: colors.dark20,
      },
    },
  }),
)
