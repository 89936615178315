export default {
  root: {
    maxHeight: '12px',
  },
  textField: {
    minWidth: '125px',
    marginTop: '-1px',
    '& input': {
      fontSize: '14px',
      padding: '0px',
    },
  },
  item: {
    fontSize: '.9rem',
    whiteSpace: 'nowrap',
  },
  readonlyItem: {
    width: 125,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    fontSize: '1rem',
  },
}
