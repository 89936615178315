import React from 'react'
import styled, { css } from 'styled-components'

import chainIcon from '~/assets/images/chain_icon.svg'
import colors from '~/utils/colors'

const ChainContainer = styled.div`
  position: relative;
  width: 100%;
`

const ChainLink = styled.div<{ $position: 'left' | 'right' }>`
  height: calc(50%);
  width: 1rem;

  top: 50%;
  transform: translateY(-50%);

  border: 2px ${colors.dark20} dashed;
  position: absolute;

  ${({ $position }) =>
    $position === 'left'
      ? css`
          border-radius: 8px 0 0 8px;
          border-right: none;
          left: -1rem;
        `
      : css`
          border-radius: 0 8px 8px 0;
          border-left: none;
          right: -1rem;
        `}
`

const ChainIconContainer = styled.div<{ $position: 'left' | 'right' }>`
  background: ${colors.white80};
  width: 20px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;

  ${({ $position }) =>
    $position === 'left'
      ? css`
          transform: translateY(-50%) translateX(-50%);
        `
      : css`
          right: 0;
          transform: translateY(-50%) translateX(50%);
        `}
`

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export function ChainLinkComponent({ children }: { children: React.ReactNode }) {
  return (
    <ChainContainer>
      <ChainLink $position="left">
        <ChainIconContainer $position="left">
          <img src={chainIcon} alt="Chain Icon" />
        </ChainIconContainer>
      </ChainLink>

      <ChildrenContainer>{children}</ChildrenContainer>
      <ChainLink $position="right">
        <ChainIconContainer $position="right">
          <img src={chainIcon} alt="Chain Icon" />
        </ChainIconContainer>
      </ChainLink>
    </ChainContainer>
  )
}
