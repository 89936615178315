import { Box, Tab, Tabs, Tooltip, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import React, { ReactNode, useState } from 'react'

import { ReactComponent as TooltipIcon } from '~/assets/images/tooltip.svg'
import { useAppState } from '~/context'
import { isAbTestEnabled } from '~/featureToggle'
import { Funnel } from '~/types'

import AbTestTabMetricsTable from '../AbTestTab/MetricsTable'
import AbTestTabNoContent from '../AbTestTab/NoContent'
import { useGetAbTestMetricsForTable } from '../AbTestTab/useGetAbTestMetrics'
import Favicon from '../Favicon'
import Metrics from '../Metrics'
import ShareAsTemplate from '../ShareAsTemplate'

interface TabPanelProps {
  children: ReactNode
  value: number
  index: number
  padding?: number
}

interface FunnelAppState {
  funnel: { data: Funnel }
}

const TabPanel = ({ children, value, index, padding }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    style={{
      background: '#fff',
      padding: `10px ${padding ? padding : 0}px`,
    }}
  >
    {value === index && <Box>{children}</Box>}
  </div>
)

function TabsPanel() {
  const [selectedTab, setSelectedTab] = useState(0)
  const {
    funnel: { data: funnel },
  }: FunnelAppState = useAppState()
  const { favicon_path, updated_at } = funnel

  const tabHeaders = [
    { id: 1, label: 'Estatísticas' },
    ...(isAbTestEnabled ? [{ id: 2, label: 'Teste A/B' }] : []),
    { id: 3, label: 'Configurações' },
  ]

  const abTestTabIndex = isAbTestEnabled ? 1 : 2
  const settingsTabIndex = isAbTestEnabled ? 2 : 1

  const { activeAbTestMetrics, completedAbTestMetrics } = useGetAbTestMetricsForTable()

  const displayNoContent = activeAbTestMetrics.length === 0 && completedAbTestMetrics.length === 0

  return (
    <Box my={3}>
      <>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => {
            setSelectedTab(newValue)
          }}
          aria-label="simple tabs example"
          indicatorColor="primary"
          TabIndicatorProps={{ children: <span /> }}
        >
          {tabHeaders.map((item) => (
            <Tab
              key={item.id}
              label={item.label}
              style={{
                fontFamily: 'Nunito Sans',
                fontWeight: 600,
                textTransform: 'capitalize',
              }}
            />
          ))}
        </Tabs>

        <div>
          <TabPanel value={selectedTab} index={0}>
            <Metrics />
          </TabPanel>

          {/* AB test */}
          <TabPanel value={selectedTab} index={abTestTabIndex}>
            {displayNoContent && <AbTestTabNoContent />}
            {activeAbTestMetrics.length > 0 && (
              <AbTestTabMetricsTable variant="active" data={activeAbTestMetrics} />
            )}
            {completedAbTestMetrics.length > 0 && (
              <AbTestTabMetricsTable variant="completed" data={completedAbTestMetrics} />
            )}
          </TabPanel>

          <TabPanel value={selectedTab} index={settingsTabIndex} padding={50}>
            <Typography
              gutterBottom
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                fontFamily: 'Nunito Sans',
                fontWeight: 600,
                fontSize: 14,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Adicionar favicon às páginas
              <Tooltip title="Ao alterar o favicon nessa configuração, ele será aplicado automaticamente em todas as páginas desse funil.">
                <TooltipIcon style={{ marginLeft: 8 }} />
              </Tooltip>
            </Typography>
            <Favicon path={favicon_path} updatedAt={updated_at} />
            <Divider />
            <ShareAsTemplate />
          </TabPanel>
        </div>
      </>
    </Box>
  )
}

export default TabsPanel
