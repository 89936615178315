import { Typography } from '@material-ui/core'
import React from 'react'

import ModalComponent from '~/components/Modal'

type DeleteAbTestModalProps = {
  /** The open state of the modal */
  open: boolean
  /** The name of the page. Used to confirm the deletion */
  name: string
  /** The handle close function */
  handleClose: () => void
  /** The handle submit function */
  handleSubmit: () => void
}

export function DeleteAbTestModal({
  open,
  name,
  handleClose,
  handleSubmit,
}: DeleteAbTestModalProps) {
  const [pageName, setPageName] = React.useState('')

  const inputs = [
    {
      component: 'textField',
      fullWidth: true,
      name: 'page-name',
      type: 'text',
      label: (
        <span style={{ fontWeight: 400 }}>
          Para confirmar, por favor digite <span style={{ fontWeight: 'bold' }}>{name}</span>
        </span>
      ),
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => setPageName(event.target.value),
    },
  ]

  const buttons = [
    {
      variant: 'outlined',
      customClass: 'error',
      name: 'Sim, exclua o teste A/B',
      disabled: name !== pageName,
      handleSubmit,
    },
  ]

  return (
    <ModalComponent
      actions={{ handleClose }}
      // @ts-expect-error Asking for unnecessary props
      state={{ open, title: 'Tem certeza?', inputs, buttons }}
    >
      <Typography variant="h6" style={{ marginTop: 0, marginBottom: 16, lineHeight: 1.5 }}>
        Tem certeza que deseja excluir o teste A/B da página:
        <br />
        {name}?
      </Typography>
    </ModalComponent>
  )
}
