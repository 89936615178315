import { createStyles, makeStyles } from '@material-ui/core'

import colors from '~/utils/colors'

export const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      color: colors.bluePrimary,
      backgroundColor: colors.white60,
      zIndex: 1,
      width: '60px',
      height: '60px',
    },

    number: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      flexDirection: 'column',
    },

    line: {
      position: 'absolute',
      height: '110%',
      border: `1px solid ${colors.blueGray}`,
      borderStyle: 'dashed',
      top: 0,

      '@media (min-width: 1920px)': {
        height: '130%',
      },

      '&.arrow': {
        display: 'flex',
        justifyContent: 'center',
        height: '110%',
      },

      '&.arrow::before': {
        content: '""',
        display: 'inline-block',
        width: 0,
        height: 0,
        border: '8px solid transparent',
        verticalAlign: 'baseline',
        borderTopColor: '#CBCBCB',
        position: 'absolute',
        bottom: -10,
      },

      '&.last': {
        display: 'none',
      },
    },
  }),
)
