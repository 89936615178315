import { Box, Grid, Typography } from '@material-ui/core'
import React, { useRef } from 'react'
import { useDrag } from 'react-dnd'

import pageIcon from '~/assets/images/pageIcon.svg'
import trashIcon from '~/assets/images/trash_icon.svg'
import Menu from '~/pages/Templates/components/Menu'
import { AbTestParams } from '~/types'
import colors from '~/utils/colors'
import menuOption from '~/utils/menuOption'

import PagePathContainer from '../PagePath'
import { StatusBadge } from '../StatusBadge'
import { useStyles } from './styles'

const ItemTypes = {
  CARD: 'card',
}

type AbTestVariantCardProps = {
  id: number
  index: number
  page: {
    id: number
    name: string
    path: string
    updated_at: string
    status: string
    funnel_id: number
    page_category: {
      description: string
    }
  }
  abTest: AbTestParams
  domainUrl: string
  setDeletePageModal: (value: any) => void
  hasActiveAbTest: boolean
}

export const AbTestVariantCard = ({
  id,
  page,
  domainUrl,
  index,
  abTest,
  setDeletePageModal,
  hasActiveAbTest,
}: AbTestVariantCardProps) => {
  const classes = useStyles()

  const ref = useRef(null)
  const [{ isDragging }] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1

  const updatedAt = new Date(page.updated_at)

  function getMenuOptions(page: AbTestVariantCardProps['page']) {
    return [
      menuOption(trashIcon, 'Deletar Teste A/B', () =>
        setDeletePageModal({
          name: page.name,
          open: true,
          pageId: page.id,
          abTestId: abTest.id,
        }),
      ),
    ]
  }

  return (
    <div>
      <Grid
        container
        className={classes.funnelInfo}
        ref={ref}
        style={{ opacity, transform: `rotate(${isDragging ? -1 : 0}deg)` }}
      >
        <Grid item xs={12} xl={2}>
          <Box display="flex" height="100%" alignItems="center" marginLeft="20px">
            <Box className={classes.dragIcon}>
              <div style={{ width: 24 }}></div>
            </Box>
            <Box className={classes.typeIcon}>
              <img src={pageIcon} alt="Ícone de uma página" />
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography className={`truncate bold`} variant="h6" gutterBottom>
                {page.name}
                <span style={{ color: colors.dark20, fontSize: 12, marginLeft: 8 }}>
                  Variante teste A/B
                </span>
              </Typography>

              <PagePathContainer
                page={page}
                domainUrl={domainUrl}
                funnelId={page.funnel_id}
                setDragMode={() => undefined}
                readOnly
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} xl={10}>
          <Box className={classes.cardInfos}>
            <div>
              <Typography component="p" gutterBottom>
                Categoria da página
              </Typography>
              <Typography component="span" gutterBottom className="bold">
                {page.page_category.description}
              </Typography>
            </div>
            <div>
              <Typography component="p" gutterBottom>
                Última modificação
              </Typography>
              <Typography component="span" gutterBottom className="bold">
                {updatedAt.toLocaleString('pt-BR', {
                  timeZone: 'America/Sao_Paulo',
                })}
              </Typography>
            </div>
            <div className={classes.infoStatus}>
              <Typography component="p" gutterBottom>
                Status
              </Typography>
              <Typography component="span" gutterBottom>
                <StatusBadge status={abTest.status} />
              </Typography>
            </div>
            <div className="editButton">
              <div style={{ width: 150 }} />
            </div>
            <Menu index={index} options={getMenuOptions(page)} disabled={hasActiveAbTest} />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
