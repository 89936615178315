import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import { Close as CloseIcon } from '@material-ui/icons'
import React from 'react'
import { toast } from 'react-toastify'

import trophyIllustration from '~/assets/images/ab_test_trophy.svg'
import axiosNewInstance from '~/services/config/axios'
import colors from '~/utils/colors'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(3),
    borderBottom: '1px solid #E5E9F5',
    '& p': {
      textAlign: 'left',
      fontSize: '24px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7, 8),
    padding: 0,
    gap: theme.spacing(4),
  },
}))

type AbTestChampionModalData = {
  abTestId: number
  pageId: number
  winnerId: number
  pageName: string
}

type AbTestChampionModalProps = {
  /** Whether the modal is open or not */
  open: boolean
  /** Data to be used by the modal */
  data: AbTestChampionModalData
  /** Function to close the modal */
  handleClose: () => void
  /** Function that's executed when the user clicks the submit button. */
  onSubmit: () => void
}

export default function AbTestChampionModal({
  open,
  data,
  handleClose,
  onSubmit,
}: AbTestChampionModalProps) {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <Typography component="p">Finalizar Teste A/B</Typography>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {data ? (
        <DialogContent className={classes.dialogContent}>
          <div style={{ textAlign: 'center', maxWidth: 400, margin: '0 auto' }}>
            <img
              src={trophyIllustration}
              alt="Trophy icon"
              style={{ width: 80, marginBottom: 24 }}
            />

            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                marginBottom: 16,
                color: '#4C6FFF',
                fontFamily: 'Nunito Sans',
              }}
            >
              Uhul! A página &quot;{data.pageName}&quot; é a Campeã?
            </Typography>

            <div style={{ color: colors.dark80 }}>
              <Typography style={{ fontSize: 14, fontFamily: 'Nunito Sans', marginBottom: 8 }}>
                Ao definir a página campeã, o teste será finalizado.
              </Typography>
              <Typography style={{ fontSize: 14, fontFamily: 'Nunito Sans' }}>
                A página perdedora será arquivada e direcionaremos 100% do tráfego para a campeã.
                Tem certeza disso?
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 'auto',
                marginRight: 'auto',
                gap: 12,
                width: 270,
                marginTop: 28,
              }}
            >
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={async () => {
                  try {
                    await axiosNewInstance.put(
                      `/pages/${data.pageId}/abtest/${data.abTestId}/choose`,
                      {
                        winner_id: data.winnerId,
                      },
                    )

                    await onSubmit()
                    handleClose()

                    toast.success('Teste AB finalizado com sucesso!')
                  } catch (error) {
                    toast.error('Erro ao finalizar o teste AB. Tente novamente mais tarde.')
                  }
                }}
              >
                Sim! Já tenho a minha campeã!
              </StyledButton>

              <StyledButton variant="outlined" fullWidth onClick={handleClose}>
                Não! Preciso de mais tempo...
              </StyledButton>
            </div>
          </div>
        </DialogContent>
      ) : null}
    </Dialog>
  )
}

const StyledButton = withStyles({
  root: {
    fontFamily: 'Nunito Sans',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 8,
  },
  contained: {
    background: colors.button.main,
  },
  outlined: {
    color: colors.button.main,
    border: `1px solid ${colors.button.main}`,
  },
})(Button)
