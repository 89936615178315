const DOMAIN_STATUS = {
  ACTIVE: 'active',
  PENDING: 'peding',
  BLOCKED: 'blocked',
} as const

const FUNNEL_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
} as const

const PAGE_STATUS = {
  PUBLISHED: 'published',
  EMPTY: 'empty',
  BLOCKED: 'blocked',
  DRAFT: 'draft',
} as const

/**
 * NOTE: This object was initially declared in ./constants.js.
 * I copied it over here to have access to its types in ts files.
 */
export const STATUS = {
  DOMAIN: DOMAIN_STATUS,
  FUNNEL: FUNNEL_STATUS,
  PAGE: PAGE_STATUS,
}

export type PageStatus = (typeof PAGE_STATUS)[keyof typeof PAGE_STATUS]

/**
 * Valid AB test statuses.
 *
 * TODO check if `inactive` is still valid.
 */
export type AbTestStatus = 'active' | 'draft' | 'completed' | 'inactive'
