import { Box, Typography } from '@material-ui/core'
import React from 'react'

import infoIcon from '~/assets/images/info_icon.svg'
import colors from '~/utils/colors'

/**
 * This component is used to display a message when there are no ab tests
 * (active or completed) in the funnel.
 */
export default function AbTestTabNoContent() {
  return (
    <Box
      style={{
        padding: '1.75rem 1.5rem',
      }}
    >
      <Box
        style={{
          backgroundColor: colors.white60,
          padding: '1.5rem',
          display: 'flex',
          gap: '1.5rem',
        }}
      >
        <div style={{ width: 67, height: 67 }}>
          <img src={infoIcon} alt="home" />
        </div>

        <div
          style={{
            color: colors.info,
          }}
        >
          <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 700 }} variant="body1">
            Atenção
          </Typography>

          <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 600 }} variant="body2">
            <span style={{ display: 'block', marginBottom: 18 }}>
              Não há histórico de testes ou testes ativos no momento.
            </span>
            <span style={{ display: 'block', marginBottom: 18 }}>
              Para criar seu primeiro teste:
            </span>
            <ol
              style={{
                listStylePosition: 'inside',
                paddingLeft: 4,
              }}
            >
              <li>Vá na página e clique nos três pontinhos à direita.</li>
              <li>Clique em “Criar teste A/B”</li>
              <li>A página será clonada para que você edite o que quiser testar.</li>
              <li>Quando concluir suas edições, inicie o teste a/b.</li>
            </ol>
          </Typography>
        </div>
      </Box>
    </Box>
  )
}
