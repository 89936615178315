import { Avatar } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { useStyles } from './styles'

const normalizePosition = (position: number) => {
  return String(position + 1).padStart(2, '0')
}

type CardPositionProps = {
  index: number
  /**
   * Whether the item is the last one
   */
  isLast: boolean
  /**
   * Whether an arrow should be displayed
   */
  hasArrow: boolean
}

export function CardPosition({ index, isLast, hasArrow }: CardPositionProps) {
  const classes = useStyles()

  return (
    <>
      <div className={clsx(classes.number)}>
        <Avatar className={classes.avatar}>{normalizePosition(index)}</Avatar>
        <div className={clsx(classes.line, { arrow: hasArrow, last: isLast })}></div>
      </div>
    </>
  )
}
