import { Typography } from '@material-ui/core'
import { FiberManualRecord as CircleIcon } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

import { default as Button } from '~/components/BaseButton'
import { SET_AB_TEST_CHAMPION_MODAL } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import { AbTestParams, Funnel, Page } from '~/types'
import colors from '~/utils/colors'

/**
 * The AB test metrics in the shape of the MetricsTable.
 */
type AbTestMetricsForTable = {
  id: number | string
  /** The page name */
  page_name?: string
  /** The test variant name */
  variant_name: string
  /** The variant traffic split percentage */
  traffic_percent: number | string
  /** The number of variant visitors */
  visits: number | string
  /** The variant conversions */
  conversions: number | string
  /** The variant conversions percentage */
  conversion_percent?: number | string
  /** The variant win chance */
  win_chance: number | string
  /** The test start date */
  start_date?: string
  /** The test end date */
  end_date?: string
  /** The action button */
  action?: React.ReactNode
  /** The status */
  status?: React.ReactNode
}

/**
 * Meta information that will be used for the winner request.
 * Is used to build the modal which houses the request factory.
 */
type AbTestPageData = {
  /** The page id. */
  pageId: number
  /** The ab test id. */
  abTestId: number
  /**
   * The soon-to-be-winner id.
   *
   * This will be the original page id if the original page winner modal is selected,
   * or the variant id if the variant winner modal is selected.
   */
  winnerId: number
}

export type AbTestMetricsForTableWithModalData = AbTestMetricsForTable & {
  pageData?: AbTestPageData
}

/**
 * Returns the active and completed AB tests metrics for the MetricsTable.
 */
export function useGetAbTestMetricsForTable() {
  const dispatch = useAppDispatch()

  const { funnel } = useAppState() as { funnel: { data: Funnel } }

  const pages = funnel.data.pages

  const activeAbTestsData = funnel.data.active_ab_tests
  const completedAbTestsData = funnel.data.completed_ab_tests

  const formattedActiveTestsData = activeAbTestsData.flatMap((data) => {
    const page = pages.filter((page) => page.id === data.page_id)[0]

    return [
      formatActiveTestsDataForTable({ id: data.id, name: page.name }, data, 'original'),
      formatActiveTestsDataForTable({ id: data.id, name: page.name }, data, 'variant'),
    ]
  })

  const formattedCompletedTestsData = completedAbTestsData.flatMap((data) => {
    const page = pages.filter((page) => page.id === data.page_id)[0]

    const isOriginalWinner = data.winner_id === data.page_id

    return [
      formatCompletedTestsDataForTable(
        { id: data.id, name: page.name },
        data,
        'original',
        isOriginalWinner,
      ),
      formatCompletedTestsDataForTable(
        { id: data.id, name: page.name },
        data,
        'variant',
        !isOriginalWinner,
      ),
    ]
  })

  function handleOpenWinnerModal(payload: AbTestMetricsForTableWithModalData) {
    dispatch({
      type: SET_AB_TEST_CHAMPION_MODAL,
      payload: {
        open: true,
        data: {
          pageName: `${payload.page_name} - ${payload.variant_name}`,
          ...payload.pageData,
        },
      },
    })
  }

  const [activeAbTestMetrics, setActiveAbTestMetrics] = useState<AbTestMetricsForTable[]>([])
  const [completedAbTestMetrics, setCompletedAbTestMetrics] = useState<AbTestMetricsForTable[]>([])

  useEffect(() => {
    async function fetchActiveAbTestMetrics() {
      const activeMetricsWithAction = addActionToMetricsTable(
        formattedActiveTestsData,
        handleOpenWinnerModal,
      )

      setActiveAbTestMetrics(activeMetricsWithAction)
      setCompletedAbTestMetrics(formattedCompletedTestsData)
    }

    fetchActiveAbTestMetrics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    activeAbTestMetrics: activeAbTestMetrics,
    completedAbTestMetrics,
  }
}

function addActionToMetricsTable(
  metrics: AbTestMetricsForTableWithModalData[],
  handleClick: (data: AbTestMetricsForTableWithModalData) => void,
) {
  return metrics?.map((metric) => {
    return {
      ...metric,
      action: (
        <Button
          variant="contained"
          color="primary"
          size="small"
          text="Definir campeã"
          handleClick={() => {
            handleClick(metric)
          }}
        />
      ),
    }
  })
}

const FinishedStatusBadge = () => {
  return (
    <Typography
      variant="body1"
      component="div"
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '0.5rem',
        color: colors.dark20,
        fontSize: '0.875rem',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <div
        style={{
          fontSize: '12px',
          lineHeight: 0,
        }}
      >
        <CircleIcon fontSize="inherit" />
      </div>
      Finalizado
    </Typography>
  )
}

/** Formats AB test data to be used on the active tests table. */
function formatActiveTestsDataForTable(
  page: Pick<Page, 'id' | 'name'>,
  abTestParams: AbTestParams,
  type: 'original' | 'variant',
): AbTestMetricsForTableWithModalData {
  const emptyValue = '-'

  const metrics = abTestParams?.metrics

  if (!metrics) {
    const emptyMetricsData = {
      id: page.id,
      page_name: page.name,
      variant_name: type === 'original' ? 'A' : 'B',
      traffic_percent: abTestParams.segments.weight + '%',
      visits: emptyValue,
      conversions: emptyValue,
      conversion_percent: emptyValue,
      win_chance: emptyValue,
      pageData: {
        pageId: abTestParams?.page_id,
        abTestId: abTestParams?.id,
        winnerId: type === 'original' ? abTestParams?.page_id : abTestParams?.variant_id,
      },
    }

    return emptyMetricsData
  }

  const parsedMetrics = getMetricsForType(metrics, type)

  const data = {
    id: page.id,
    page_name: page.name,
    variant_name: type === 'original' ? 'A' : 'B',
    traffic_percent: abTestParams.segments.weight + '%',
    visits: parsedMetrics.visits ?? emptyValue,
    conversions: parsedMetrics.conversions ?? emptyValue,
    conversion_percent: parsedMetrics.conversionPercent ?? emptyValue,
    win_chance: parsedMetrics.winChance ?? emptyValue,
    pageData: {
      pageId: abTestParams?.page_id,
      abTestId: abTestParams?.id,
      winnerId: type === 'original' ? abTestParams?.page_id : abTestParams?.variant_id,
    },
  }

  return data
}

/** Formats AB test data to be used on the completed tests table. */
function formatCompletedTestsDataForTable(
  page: Pick<Page, 'id' | 'name'>,
  abTestParams: AbTestParams,
  type: 'original' | 'variant',
  isWinner?: boolean,
): AbTestMetricsForTableWithModalData {
  const emptyValue = '-'

  const metrics = abTestParams?.metrics

  if (!metrics) {
    const emptyMetricsData = {
      id: page.id,
      page_name: page.name + (isWinner ? ' 🏆' : ''),
      variant_name: type === 'original' ? 'A' : 'B',
      traffic_percent: abTestParams.segments.weight + '%',
      start_date: formatBrazilianDate(abTestParams.created_at),
      end_date: formatBrazilianDate(abTestParams.updated_at),
      visits: emptyValue,
      conversions: emptyValue,
      conversion_percent: emptyValue,
      win_chance: emptyValue,
      action: null,
      status: <FinishedStatusBadge />,
    }

    return emptyMetricsData
  }

  const parsedMetrics = getMetricsForType(metrics, type)

  const data = {
    id: page.id,
    page_name: page.name + (isWinner ? ' 🏆' : ''),
    variant_name: type === 'original' ? 'A' : 'B',
    traffic_percent: abTestParams.segments.weight + '%',
    start_date: formatBrazilianDate(abTestParams.created_at),
    end_date: new Date(abTestParams.updated_at).toLocaleDateString('pt-BR'),
    visits: parsedMetrics.visits ?? emptyValue,
    conversions: parsedMetrics.conversions ?? emptyValue,
    conversion_percent: parsedMetrics.conversionPercent ?? emptyValue,
    win_chance: parsedMetrics.winChance ?? emptyValue,
    action: null,
    status: <FinishedStatusBadge />,
  }

  return data
}

/**
 * Returns the correct metrics for a given page type.
 *
 * For example, if type === 'original', the metrics of the original page will be returned.
 * If 'variant' is used, the metrics of the variant page will be returned instead.
 */
function getMetricsForType(metrics: AbTestParams['metrics'], type: 'original' | 'variant') {
  const visits = type === 'original' ? metrics?.index_visits : metrics?.variant_visits

  const conversions =
    type === 'original'
      ? metrics?.conversion_page_index_visits
      : metrics?.conversion_page_variant_visits

  const conversionPercent =
    type === 'original' ? metrics?.index_conversion : metrics?.variant_conversion

  return {
    visits,
    conversions,
    conversionPercent,
    /**
     * NOTE win chance will temporarily will use the conversion percent value.
     * It should be updated once the backend sends this value.
     */
    winChance: conversionPercent,
  }
}

/**
 * Converts a date string into the hh:mm dd/mm/yy format.
 *
 * @param dateString The date string to be formatted. Should be in the ISO 8601 string format.
 * @returns The formated date
 */
function formatBrazilianDate(dateString: string) {
  const date = new Date(dateString)

  const timePart = date.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  const datePart = date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })

  return `${timePart} ${datePart}`
}
