import { Box, CircularProgress } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import CustomTable from '~/components/Table'
import { PLAN_CODE, SET_ALERT } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import { getPagesMetrics } from '~/services/funnelService'

import ClearMetrics from './components/ClearMetrics'

function Metrics() {
  const {
    currentSubscription: { plan },
  } = useAppState()
  const [list, setList] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const { id } = useParams()
  const dispatchApp = useAppDispatch()
  const isSubscriptionFree = plan?.plan?.code === PLAN_CODE.FREE

  const getMetrics = useCallback(async () => {
    setIsFetching(true)
    try {
      const response = await getPagesMetrics(id)
      const {
        data: { data },
      } = response
      const tableList = data.map((el) => {
        return { step: el.step, name: el.name, ...el.metrics }
      })
      setList(tableList)
    } catch (error) {
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Não foi possível carregar as métricas das página',
          variant: 'error',
        },
      })
    }
    setIsFetching(false)
  }, [dispatchApp, id])

  useEffect(() => {
    getMetrics()
  }, [getMetrics, id])

  const reloadMetrics = () => {
    setList([])
    getMetrics()
  }

  const tableKeys = ['step', 'name', 'visits', 'unique_visits', 'conversion', 'general_conversion']

  if (!list.length || isFetching)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="250px">
        <CircularProgress />
      </Box>
    )

  return (
    <>
      {!isSubscriptionFree && (
        <Box textAlign="right" px={3}>
          <ClearMetrics id={id} reload={reloadMetrics} />
        </Box>
      )}
      <CustomTable
        state={{
          header: [
            { name: 'Posição', align: 'center' },
            { name: 'Páginas', align: 'center' },
            { name: 'Visitas', align: 'center' },
            { name: 'Visitas únicas', align: 'center' },
            {
              name: 'Conversão*',
              align: 'center',
              tooltip:
                '*Esta conversão é apenas uma estimativa com base no número de visitas de cada página. Ela não leva em consideração a % real de pessoas que passaram primeiro pela página anterior e chegaram na próxima.',
            },
            {
              name: 'Conversão Geral*',
              align: 'center',
              tooltip:
                '*Esta métrica é apenas uma estimativa de conversão da página em relação ao funil, com base no número de visitas da posição 1. Ela não leva em consideração a % real de pessoas que passaram por cada etapa até chegar na posição em questão.',
            },
          ],
          rows: list,
        }}
        keys={tableKeys}
        isDisabled={isSubscriptionFree}
      />
    </>
  )
}

export default Metrics
