import { AbTestStatus, PageStatus } from '~/consts'

type Nullable<T> = T | null

type Card = {
  holder_name: string
  exp_date: string
  last_four_digits: string
}

export type Features = {
  name: string
  slug: string
  value: string
}

/**
 * The AB test metrics from the API response shape.
 */
export type AbTestMetricsFromApi = {
  /** The AB test id. */
  id: number
  conversion_page_id: number
  conversion_page_index_visits: number
  conversion_page_unique_visits: number
  conversion_page_variant_visits: number
  conversion_page_visits: number
  /** The original page id. */
  index_id: number
  index_conversion: number
  index_unique_visits: number
  index_visits: number
  /** The variant page id. */
  variant_id: number
  variant_conversion: number
  variant_unique_visits: number
  variant_visits: number
}

/** The params of the AB Test field of a page */
export type AbTestParams = {
  /** The AB test id (based on the AB test table, it seems) */
  id: number
  /** The original page id. */
  page_id: number
  /** The AB test variant id. */
  variant_id: number
  /** The AB test winner id. */
  winner_id: number | null
  /** The AB test status. */
  status: AbTestStatus
  /** The AB test metrics. */
  metrics: AbTestMetricsFromApi | null
  created_at: string
  updated_at: string
  /** The AB test segments. */
  segments: {
    type: string
    weight: number
  }
}

export type Page = {
  id: number
  name: string
  path: string
  /**
   * TODO confirm all possible statuses for pages
   */
  status: PageStatus | string
  step: number
  type: string
  page_category_id: number
  page_category: {
    id: number
    name: string
    description: string
    created_at: string
    updated_at: string
    deleted_at: null
  }
  preview_image_url?: string
  funnel_id: number
  url: string
  created_at: string
  updated_at: string
  abTest: AbTestParams | null
}

export enum SharingStatus {
  STOPED = 'stoped',
  PROCESSING = 'processing',
  PUBLISHED = 'published',
}

type SharingAsTemplate = {
  share_uuid: string
  status: SharingStatus
}

export type Funnel = {
  id: number
  name: string
  favicon_path: string | null
  subscriber_id: number
  funnel_category_id: number
  domain_id: number
  pages: Page[]
  created_at: string
  updated_at: string
  domain: Domain
  sharing_as_template: SharingAsTemplate | null
  active_ab_tests: AbTestParams[]
  completed_ab_tests: AbTestParams[]
}

export type Domain = {
  id: number
  name: string
  is_mf_domain?: boolean
  subscriber_id?: number
  funnels?: Funnel[]
  created_at?: string
  updated_at?: string
  verification_token?: string | null
  status?: string
  ssl_active?: number | null
  ssl_requested_at?: string
  ssl_activated_at?: string
  default_page_id?: number
  nickname?: string
  metadata?: string | null
  default_invalid_page_id?: number | null
}

export type Offer = {
  offer_code: string
  offer_name: string
  offer_price: number
  offer_initial_price: number
  offer_initial_period: Nullable<number>
  offer_initial_interval: Nullable<number>
  offer_period: number
  offer_interval: string
  offer_trial_days: number
  installments: string[]
}

export type Plan = {
  name: string
  code: string
  offer?: Offer
  offers?: Offer[]
  can_upgrade_to_plans: string[]
  features: Features[]
  must_select_resources?: boolean
  default_offer_index?: number
}

export type Subscription = {
  id: number
  plan_id: number
  status: string
  can_upgrade: boolean
  plan: Plan
  paymentplatform_reference_id: string
  expires_at: string
  starts_at: Nullable<string>
  trial_days_left: number
  subscriber_id: number
  paymentplatform_id: number
  affiliate_id: Nullable<number>
  created_at: string
  updated_at: string
  card?: Card | null | undefined
  domains?: Domain[]
}

export type Profile = {
  id: number
  name: string
  email: string
  email_verified_at: string
  image: string
  company_name: string
  role: string
  verified: string
  affiliate: number
  created_at: string
  updated_at: string
  deleted_at: string | null
  aff_commission: string
  aff_status: string
  current_subscription_id: number
  auto_update_commission: number
  phone_ddd: string | null
  phone_number: string | null
  base_domain_id: number
}

export type AppState = {
  funnel: { data: Funnel; isFetching: boolean }
  domains: { data: Domain[]; isFetching: boolean }
}

export enum SHARING_AS_TEMPLATE_STATUS {
  PROCESSING = 'processing',
  STOPED = 'stoped',
  PUBLISHED = 'published',
}

export enum ASYNC_STATUS {
  IDLE = 'idle',
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

export enum AUTH_LOGIN {
  SUCCESS = 'success',
  FAIL = 'fail',
}
