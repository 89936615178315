import { Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, { useState } from 'react'

import { default as Button } from '~/components/BaseButton'

import CreatePageModal from './CreatePageModal'

/**
 * The Button to trigger the "add new page" modal
 *
 * @param {object} props
 * @param {number} props.numberOfPages The number of pages in the current funnel
 */
function AddNewPageButton({ numberOfPages }) {
  const size = numberOfPages === 0 ? 'large' : 'small'

  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  return (
    <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={4}>
      <Button
        size={size}
        variant="outlined"
        handleClick={() => {
          toggleOpen()
        }}
        color="primary"
        text="Nova Página"
        fullWidth={numberOfPages === 0}
        startIcon={<AddIcon />}
      />

      <CreatePageModal open={open} handleClose={toggleOpen} nextStep={numberOfPages + 1} />
    </Box>
  )
}

export default AddNewPageButton
