import { Box, Grid, Tooltip } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React from 'react'

import { FeatureBlock } from '../UpgradeModal'
import { useStyles } from './styles'

type DefaultRowType = {
  [key: string]:
    | string
    | number
    | React.ReactNode
    | {
        [key: string]: string | number
      }
}

export type CustomTableProps<TRows = DefaultRowType> = {
  /** The table state */
  state: {
    /** The table header. */
    header: {
      /** The header name. */
      name: string
      /** The header tooltip's text content. */
      tooltip?: string
    }[]
    /**
     * The data to be displayed in the table. It consists of an object where
     * the keys are the data keys and the values are the data values. The keys
     * must be present in the `keys` prop in order to be displayed.
     */
    rows: TRows[]
  }
  /**
   * The data keys that should be displayed in the table. If a key is not
   * declared here, it will not be displayed in the table. This will also
   * determine the order of the columns in the table.
   */
  keys: string[]
  /** Whether the table is disabled. */
  isDisabled: boolean
  /** The table background color. */
  backgroundType?: 'alternating' | '2-grouped' | undefined
}

function CustomTable<RowType extends DefaultRowType>({
  state: { header, rows },
  keys,
  isDisabled,
  backgroundType,
}: CustomTableProps<RowType>) {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {isDisabled && (
        <FeatureBlock message="Para visualizar as métricas completas do seu funil, faça upgrade do seu plano." />
      )}

      <Box className={`${classes.root} ${isDisabled && 'disabled'}`}>
        <Grid className={classes.table} aria-label="simple table">
          <Box className={classes.header}>
            {header.map((item, index) => (
              <Tooltip key={index} title={item.tooltip || ''} placement="top">
                <Box className="item">
                  {item.name}{' '}
                  {item.tooltip && <HelpOutlineIcon style={{ height: 18, color: '#888' }} />}
                </Box>
              </Tooltip>
            ))}
          </Box>

          {rows.map((row, index) => {
            const rowNumber = index

            function getBackgroundColor(backgroundType: 'alternating' | '2-grouped' | undefined) {
              const accentBackgroundColor = '#f7f7f7'

              if (backgroundType === 'alternating') {
                return rowNumber % 2 === 0 ? 'white' : accentBackgroundColor
              }

              if (backgroundType === '2-grouped') {
                return rowNumber % 4 === 0 || rowNumber % 4 === 1 ? 'white' : accentBackgroundColor
              }

              return 'white'
            }

            const backgroundColor = getBackgroundColor(backgroundType)

            return (
              <Box className={classes.info} key={index} style={{ backgroundColor }}>
                {keys.map((key, index) => {
                  const value = row[key]

                  return (
                    <Box key={index} className="item">
                      {/* @ts-expect-error Don't know how to fix this yet. */}
                      {value}
                    </Box>
                  )
                })}
              </Box>
            )
          })}
        </Grid>
      </Box>
    </div>
  )
}

export default CustomTable
