import { Box, TextField, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import {
  Cancel as CancelIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  Save as SaveIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function PagePath({
  path,
  domainUrl,
  editMode,
  changePath,
  handleClickCopyButton,
  handleChange,
  handleEdit,
  handleSave,
  handleCancel,
  readOnly,
}) {
  const classes = useStyles()

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        ml={2}
        className={classes.root}
      >
        <Box>
          <IconButton aria-label="copy" onClick={handleClickCopyButton}>
            <FileCopyIcon className={classes.icon} />
          </IconButton>
        </Box>
        <Typography variant="body1" component="p" className={classes.item}>
          {domainUrl}/
        </Typography>

        {readOnly ? (
          <Typography variant="body1" component="p" className={classes.readonlyItem}>
            {path}
          </Typography>
        ) : (
          <TextField
            name="path"
            value={path}
            size="small"
            onChange={handleChange}
            disabled={!editMode}
            className={classes.textField}
          />
        )}

        {!readOnly && changePath && (
          <Box display="flex" justifyContent="space-around">
            <IconButton aria-label="save" onClick={handleSave}>
              <SaveIcon className={classes.icon} />
            </IconButton>
            <IconButton aria-label="delete" onClick={handleCancel}>
              <CancelIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}

        {!readOnly && !changePath && (
          <Box>
            <IconButton aria-label="edit" onClick={handleEdit}>
              <EditIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  )
}

PagePath.propTypes = {
  path: PropTypes.string,
  domainUrl: PropTypes.string,
  editMode: PropTypes.bool,
  changePath: PropTypes.bool,
  handleClickCopyButton: PropTypes.func,
  handleChange: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  readOnly: PropTypes.bool,
}
